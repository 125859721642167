// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtJWhVGqAbD2-OBRjvg332qx7Hb5Y7Dn8",
  authDomain: "dz-inhs-web-dev.firebaseapp.com",
  projectId: "dz-inhs-web-dev",
  storageBucket: "dz-inhs-web-dev.firebasestorage.app",
  messagingSenderId: "637789434743",
  appId: "1:637789434743:web:634c132b4ef18c55782772",
  measurementId: "G-QKQ088GB90"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;
export {analytics};