import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.title : "Our Services"}</h2>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <p>{props.data ? props.data.search : "loading..."}</p>
        <button id="searchWidgetTrigger" className="btn btn-custom btn-lg" style={{ border: '2px solid white' }}>Click here to search</button>
      </div>
    </div>
  );
};
