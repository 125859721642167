import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/*<h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>*/}
                <div>
                  <img src="img/logo_transparent.png" style={{ width: '50%' }} alt="Deep Zone"></img>
                </div>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div style={{ marginBottom: '50px'}}>
                  <img src="img/Google_Cloud_Partner_outline_horizontal.png" style={{ width: '35%' }}></img>
                </div>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  {props.data ? props.data.learnMore : "learn more"}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
